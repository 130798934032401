const colors = require("tailwindcss/colors");
const defaultTheme = require("tailwindcss/defaultTheme");

module.exports = {
	content: [
		"./pages/**/*.{js,ts,jsx,tsx}",
		"./components/**/*.{js,ts,jsx,tsx}",
		"./util/**/*.{js,ts,jsx,tsx}",
	],
	theme: {
		extend: {
			width: {
				"3/8": "37.5%",
				"5/8": "62.5%",
			},
			minHeight: {
				spp: "500px",
			},
			keyframes: {
				"fade-in": {
					"0%": { opacity: "0" },
					"100%": { opacity: "1" },
				},
			},
			animation: {
				"fade-in": "fade-in 0.1s ease-out",
			},
			colors: {
				jane: colors.slate,
				"jane-text": "#414B60",
			},
			//extend grid
			screens: {
				"2xs": "235px",
				//

				xs: "475px",
				// we need large-medium (lmd) for the side filters
				lmd: "910px",
				//
				...defaultTheme.screens,
				//

				"2xl": "1536px",
				//

				"3xl": "1920px",
			},
		},
	},
	plugins: [
		require("tailwindcss-rtl"),
		require("@tailwindcss/forms"),
		require("@tailwindcss/line-clamp"),
		function ({ addUtilities }) {
			const newUtilities = {
				".remove-clear::-ms-clear": {
					display: "none",
				},
				".remove-clear::-webkit-search-cancel-button": {
					"-webkit-appearance": "none",
					appearance: "none",
				},
			};
			addUtilities(newUtilities, ["responsive", "hover"]);
		},
	],
};
